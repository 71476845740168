import Vue from 'vue'
import App from '@/App.vue'
import ImCarousel from '@/components/Carousel.vue'
import ElectroRecharge from '@/components/ElectroRecharge.vue'
import CardProduct from '@/components/CardProduct.vue'
import CardFeatured from '@/components/CardFeatured.vue'
import TokenizedCardList from '@/components/TokenizedCardList'
import TokenizedPayment from '@/components/TokenizedPayment'
import CreditCardBack from '@/components/TokenizedPayment/CreditCardBack'
import PaymentCheckout from '@/components/PaymentCheckout'
import EventBus from '@/plugins/event-bus'
import CustomSelect from '@/components/shared/CustomSelect'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// import Http from '@/services/http'
// import NumberFormat from '@/filters/number-format'
Vue.use(EventBus)
Vue.config.productionTip = false

// Vue.use(Http)
// Vue.use(NumberFormat)
Vue.component('v-select', vSelect)
Vue.component('im-carousel', ImCarousel)
Vue.component('im-electro-recharge', ElectroRecharge)
Vue.component('im-card-product', CardProduct)
Vue.component('im-card-featured', CardFeatured)
Vue.component('tokenized-card-list', TokenizedCardList)
Vue.component('tokenized-payment', TokenizedPayment)
Vue.component('credit-card-back', CreditCardBack)
Vue.component('payment-checkout', PaymentCheckout)
Vue.component('custom-select', CustomSelect)
const config = {}

if (process.env.NODE_ENV !== 'production') {
  config
    .render = h => h(App)
}

document.addEventListener('DOMContentLoaded', function () {
  window.vm = new Vue(config).$mount('#app-view')
})

document.addEventListener('VueForceRender', function () {
  window.vm.$destroy()
  delete window.vm
  window.vm = new Vue(config).$mount('#app-view')
})
