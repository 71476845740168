/* eslint-disable */
import { http } from '@/services/http'
import { ValidationObserver } from 'vee-validate'
import { numberFormat } from '@/filters/number-format'
import { required, regex, numeric } from 'vee-validate/dist/rules'

import {
  ValidationProvider,
  extend,
  localize
} from 'vee-validate/dist/vee-validate.full'

import dictionary from '@/translations/payment-checkout'

import Installment from '@/components/Installment'
import BlackoutLoader from '@/components/shared/BlackoutLoader'
import CreditCardBack from '@/components/TokenizedPayment/CreditCardBack'

export default {
  name: 'TokenizedPayment',
  props: {
    installmentsList: { type: Object }
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    Installment,
    BlackoutLoader,
    CreditCardBack
  },
  data () {
    return {
      activeInstallment: null,
      installments: null,
      payment: null,
      card: null,
      urls: null,
      installmentSelected: false,
      cvc: '',
      canContinue: false,
      componentKey: 'installments',
      selectToken: null,
      rules: {
        cvc: {
          required: true,
          numeric: true,
          regex: /^[0-9]{3,4}$/
        }
      },
      paymentType: "cash"
    }
  },
  computed: {
    enterClass () {
      if (this.componentKey === 'cvc') {
        return 'fadeInRight'
      } else {
        return 'fadeInLeft'
      }
    },
    leaveClass () {
      if (this.componentKey === 'cvc') {
        return 'fadeOutLeft'
      } else {
        return 'fadeOutRight'
      }
    },
    total () {
      if (this.activeInstallment != null) {
        if (this.installments[this.activeInstallment].coefficient === 1) {
          const total = this.installments[0].price
          return total
        } else {
          const final =
            this.installments[this.activeInstallment].price *
            this.installments[this.activeInstallment].installment
          return final
        }
      } else if (this.card.type === 'debit') {
        const total = this.installments[0].price
        return total
      }
    }
  },
  methods: {
    parseData () {
      if (typeof this.installmentsList === 'string') {
        const list = JSON.parse(this.installmentsList)
        console.log(list);
        this.installments = list.installments
        this.urls = list.urls
        this.card = list.card
        this.payment = list.payment
        this.paymentType = list.paymentType
      } else {
        this.card = this.installmentsList.card
        this.installments = this.installmentsList.installments
        this.urls = this.installmentsList.urls
      }
    },
    loadSDK () {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = this.urls.api
      document.body.appendChild(script)
    },
    selectInstallment () {
      if (!this.installmentSelected && this.activeInstallment != null) {
        window.scrollTo(0, 0)
        this.installmentSelected = true
        this.componentKey = 'cvc'
      } else {
        this.installmentSelected = false
        this.activeInstallment = null
        this.cvc = ''
        this.componentKey = 'installments'
      }
    },
    buy () {
      this.canContinue = true
      window.location.href = this.urls.action
    },
    onSubmit (event) {
      const form = event.target
      this.canContinue = true
      if (this.installments.length === 1) {
        this.activeInstallment = 0
      }
      document.body.style.overflow = 'hidden'
      this.paymentHandler(form)
    },
    paymentHandler (form) {
      const cyberSourceDisabled = true
      const gateway = new Decidir(this.payment.api, cyberSourceDisabled)
      gateway.setPublishableKey(this.payment.public_key)
      gateway.setTimeout(10000)
      gateway.createToken(form, this.responseHandler)
    },
    responseHandler (status, res) {
      const data = {}
      if (status === 200 || status === 201) {
        data.card = {
          bin: res.bin,
          last_four_digits: res.last_four_digits,
          identification: {
            number: res.cardholder.identification.number,
            name: res.cardholder.name
          }
        }

        data.payment = {
          gateway: 'decidir',
          type: this.paymentType, // cash | points | mix
          token: res.id,
          installment_selected: this.installments[this.activeInstallment].id,
          tokenized:true
        }

      } else if (status === 504) {
        data.error = [
          {
            type: 'timeout_get_token'
          }
        ]
      } else if (res.error === undefined) {
        data.error = [
          {
            type: 'invalid_authentication'
          }
        ]
      } else {
        const errors = []

        for (const error of res.error) {
          errors.push({ type: error.error.type })
        }

        data.error = errors
      }

      this.sendPayment(data)
    },
    sendPayment (data) {
      http.post(this.urls.action, data).then(res => {
        window.location.href = res.data.url
      })
      // .catch(err => {
      //   // console.error(err)
      // })
    }
  },
  filters: {
    numberFormat
  },
  watch: {
    cvc () {
      this.cvc = this.cvc.replace(/[^0-9]/g, '')
      this.$cardBus.$emit('change-cvc', this.cvc)
    }
  },
  beforeMount () {
    this.parseData()
  },
  created () {
    extend('required', required)
    extend('regex', regex)
    extend('numeric', numeric)
    localize('es', dictionary)
  },
  mounted () {
    this.loadSDK()
  },
}
