import cardMixin from '@/mixins/card'
import BlackoutLoader from '@/components/shared/BlackoutLoader'
import installment from '@/components/Installment'
export default {
  name: 'DesktopForm',
  mixins: [cardMixin],
  components: {
    BlackoutLoader,
    installment
  },
  data () {
    return {
      isActive: false,
      isForm: true,
      activeInstallment: null
    }
  },
  computed: {
    enterClass () {
      if (this.isForm) {
        return 'fadeInRight'
      } else {
        return 'fadeInLeft'
      }
    },
    leaveClass () {
      if (this.isForm) {
        return 'fadeOutLeft'
      } else {
        return 'fadeOutRight'
      }
    }
  },
  methods: {
    next () {
      this.isForm = false
    },
    backToForm () {
      this.cvc = null
      this.installment = ''
      this.isForm = true
      let placeholder = '•••'
      if (this.cardtype === 'american express') {
        placeholder = '••••'
      }
      this.$cardBus.$emit('set-cvc', placeholder)
    }
  }
}
