export default {
  name: 'CreditCard',
  data () {
    return {
      isFlipped: false,
      cardtopcolor: 'grey',
      cardbottomcolor: 'greydark',
      cardImage: 'default',

      form: {
        number: '•••• •••• •••• ••••',
        name: '',
        expiry: '••/••',
        cvc: '•••'
      },

      icon: '',
      labels: {
        // number: 'Numero',
        // name: 'Nombre completo',
        // expiry: 'Vencicmiento',
        // cvc: 'Cód. de Seguridad'
      }
    }
  },
  methods: {
    toggleFlipCard () {
      this.isFlipped = !this.isFlipped
    },
    updateName (data) {
      this.form.name = data
    },
    updateNumber (number) {
      this.form.number = number
    },
    updateExpiry (data) {
      this.form.expiry = data
    },
    updateCvc (data) {
      this.form.cvc = data
    },
    updateTopColor (color) {
      this.cardtopcolor = color
    },
    updateBottomColor (color) {
      this.cardbottomcolor = color
    },
    updateImage (image) {
      this.cardImage = image
    },
    updateIcon (icon) {
      this.icon = icon
    }
  },
  created () {
    this.$cardBus.$on('set-name', name => this.updateName(name))
    this.$cardBus.$on('set-number', number => this.updateNumber(number))
    this.$cardBus.$on('set-expiry', expiry => this.updateExpiry(expiry))
    this.$cardBus.$on('set-cvc', cvc => this.updateCvc(cvc))
    this.$cardBus.$on('toggleFlipCard', () => this.toggleFlipCard())
    this.$cardBus.$on('change-top-color', color => this.updateTopColor(color))
    this.$cardBus.$on('change-image', image =>
      this.updateImage(image)
    )
    this.$cardBus.$on('change-bottom-color', color =>
      this.updateBottomColor(color)
    )
    this.$cardBus.$on('change-icon', icon => this.updateIcon(icon))
  }
}
