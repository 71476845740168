import { http } from '@/services/http'

const rule = {}
let oldBin = null
rule.params = ['url']

rule.message = 'Tarjeta no permitida'

rule.validate = (value, args) => {
  const count = value.length
  const key = 'checkb'

  if (count < 7) {
    oldBin = null
    sessionStorage.removeItem(key)
    document.dispatchEvent(new CustomEvent('check', { detail: {} }))
    return true
  }

  if (count >= 7) {
    new Promise(resolve => {
      const number = value.replace(/ /g, '')
      const bin = parseInt(number.substring(0, 6))   
      // eslint-disable-next-line eqeqeq
      if (oldBin != bin) {     
        oldBin = bin
        http
          .post(args.url, { bin })
          .then(res => {
            const check = res.data.bin !== false
            if (check === true) {
              document.dispatchEvent(
                new CustomEvent('check', { detail: res.data })
              )
            }

            sessionStorage.setItem(key, check)
            resolve({ valid: check })
          })
          .catch(err => {
            if (err) {
              console.log(err)
            }
            resolve({ valid: false })
          })
      }
    })
  }
  const check = sessionStorage.getItem(key)
  if (count > 7 && (check === false || check === 'false')) {    
    return rule.message
  }

  return true
}

export default rule
