<template>
  <div class="creditcard flipped">
  <div class="front">
    <div class="ccsingle" v-html='icon'></div>
      <svg version="1.1" id="cardfront" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 750 471" style="enable-background:new 0 0 750 471;" xml:space="preserve">
        <g id="Front">
          <g id="CardBackground">
            <g id="Page-1_1_">
              <g id="amex_1_">
                <path id="Rectangle-1_1_" class="lightcolor" v-bind:class="cardtopcolor" d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40C0,17.9,17.9,0,40,0z" />
              </g>
            </g>
            <path class="darkcolor" v-bind:class="[cardbottomcolor]" d="M750,431V193.2c-217.6-57.5-556.4-13.5-750,24.9V431c0,22.1,17.9,40,40,40h670C732.1,471,750,453.1,750,431z" />
          </g>
          <text transform="matrix(1 0 0 1 60.106 343.0121)" id="svgnumber" class="st2 st3 st4"></text>
          <text transform="matrix(1 0 0 1 54.1064 428.1723)" id="svgname" class="st2 st5 st6"></text>
          <text transform="matrix(1 0 0 1 54.1074 389.8793)" class="st7 st5 st8"></text>
          <text transform="matrix(1 0 0 1 479.7754 388.8793)" class="st7 st5 st8"></text>
          <text transform="matrix(1 0 0 1 65.1054 241.5)" class="st7 st5 st8"></text>
          <g>
            <text transform="matrix(1 0 0 1 430.4219 433.8095)" id="svgexpire" class="st2 st5 st9"></text>
            <text transform="matrix(1 0 0 1 339.3848 417.0097)" class="st2 st10 st11">VALID</text>
            <text transform="matrix(1 0 0 1 339.3848 435.6762)" class="st2 st10 st11">THRU</text>
          </g>
          <g id="cchip">
            <g>
              <path class="st2" d="M168.1,143.6H82.9c-10.2,0-18.5-8.3-18.5-18.5V74.9c0-10.2,8.3-18.5,18.5-18.5h85.3c10.2,0,18.5,8.3,18.5,18.5v50.2C186.6,135.3,178.3,143.6,168.1,143.6z" />
            </g>
            <g>
              <g>
                <rect x="82" y="70" class="st12" width="1.5" height="60" />
              </g>
              <g>
                <rect x="167.4" y="70" class="st12" width="1.5" height="60" />
              </g>
              <g>
                <path class="st12" d="M125.5,130.8c-10.2,0-18.5-8.3-18.5-18.5c0-4.6,1.7-8.9,4.7-12.3c-3-3.4-4.7-7.7-4.7-12.3c0-10.2,8.3-18.5,18.5-18.5s18.5,8.3,18.5,18.5c0,4.6-1.7,8.9-4.7,12.3c3,3.4,4.7,7.7,4.7,12.3C143.9,122.5,135.7,130.8,125.5,130.8z M125.5,70.8c-9.3,0-16.9,7.6-16.9,16.9c0,4.4,1.7,8.6,4.8,11.8l0.5,0.5l-0.5,0.5c-3.1,3.2-4.8,7.4-4.8,11.8c0,9.3,7.6,16.9,16.9,16.9s16.9-7.6,16.9-16.9c0-4.4-1.7-8.6-4.8-11.8l-0.5-0.5l0.5-0.5c3.1-3.2,4.8-7.4,4.8-11.8C142.4,78.4,134.8,70.8,125.5,70.8z" />
              </g>
              <g>
                <rect x="82.8" y="82.1" class="st12" width="25.8" height="1.5" />
              </g>
              <g>
                <rect x="82.8" y="117.9" class="st12" width="26.1" height="1.5" />
              </g>
              <g>
                <rect x="142.4" y="82.1" class="st12" width="25.8" height="1.5" />
              </g>
              <g>
                <rect x="142" y="117.9" class="st12" width="26.2" height="1.5" />
              </g>
            </g>
          </g>
        </g>
        <g id="Back">
        </g>
      </svg>
  </div>
  <div class="back" :class="[cardImage]">
    <svg version="1.1" id="cardback" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 750 471" style="enable-background:new 0 0 750 471;" xml:space="preserve">
      <g id="Front">
        <line class="st0" x1="35.3" y1="10.4" x2="36.7" y2="11" />
      </g>
        <g id="Back">
          <g id="Page-1_2_">
            <g id="amex_2_">
              <path id="Rectangle-1_2_" class="darkcolor" :class='cardbottomcolor'  d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40C0,17.9,17.9,0,40,0z" />
            </g>
          </g>
          <rect y="61.6" class="st2" width="750" height="78" :class="cardImage === '' ? '' : 'hidden'" />
          <g>
            <path class="st3" d="M701.1,249.1H48.9c-3.3,0-6-2.7-6-6v-52.5c0-3.3,2.7-6,6-6h652.1c3.3,0,6,2.7,6,6v52.5C707.1,246.4,704.4,249.1,701.1,249.1z" />
            <rect x="42.9" y="198.6" class="st4" width="664.1" height="10.5" />
            <rect x="42.9" y="224.5" class="st4" width="664.1" height="10.5" />
            <path class="st5" d="M701.1,184.6H618h-8h-10v64.5h10h8h83.1c3.3,0,6-2.7,6-6v-52.5C707.1,187.3,704.4,184.6,701.1,184.6z" />
          </g>
          <text transform="matrix(1 0 0 1 621.999 227.2734)" id="svgsecurity" class="st6 st7"> {{ cvc }} </text>
          <g class="st8">
            <text transform="matrix(1 0 0 1 518.083 280.0879)" class="st9 st6 st10"></text>
          </g>
          <rect x="58.1" y="378.6" class="st11 hidden" width="375.5" height="13.5" />
          <rect x="58.1" y="405.6" class="st11 hidden" width="421.7" height="13.5" />
          <text transform="matrix(1 0 0 1 59.5073 228.6099)" id="svgnameback" class="st12 st13 signature"></text>
        </g>
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CreditCardBack',
  props: {
    'bin': String
  },
	data () {
		return {
			cardImage: '',
			cvc: '',
      cardtopcolor: 'blue',
      cardbottomcolor: 'bluedark'
		}
	},
	methods: {
		setImage () {
			switch (this.bin) {
        case '406997':
					this.cardImage = 'vs-black'
					break
				case '519702':
					this.cardImage = 'mc-black'
					break
				case '553646':
					this.cardImage = 'mc-chip-black'
					break
				case '553646':
					this.cardImage = 'vs-platinum'
					break
				case '524348':
				case '525468':
					this.cardImage = 'mc-platinum'
					break
				case '528731':
					this.cardImage = 'mc-chip-platinum'
					break
				case '455198':
					this.cardImage = 'vs-gold'
					break
				case '546552':
					this.cardImage = 'mc-gold'
					break
				case '455197':
					this.cardImage = 'vs-inter'
					break
				case '528587':
					this.cardImage = 'mc-inter'
					break
				case '528587':
					this.cardImage = 'mc-regional'
					break
				case '493768':
					this.cardImage = 'vs-business'
					break
				case '451772':
				case '410853':
				case '451767':
					this.cardImage = 'vs-debito'
					break
				case '420061':
				case '410378':
					this.cardImage = 'vs-corporativa'
          break
      }
      if (this.cardImage != '') {
        this.cardbottomcolor = 'transparent'
      } 
		},
		setCvc (cvc) {
			this.cvc = cvc
		}
  },
  beforeMount () {
    this.setImage()
  },
	created () {
    this.$cardBus.$on('change-cvc', cvc =>	this.setCvc(cvc))
  }
}
</script>
<style scoped>

body {
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 100vh;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.payment-title {
  width: 100%;
  text-align: center;
}

.field-container {
  position: relative;
}

.form-container .field-container:first-of-type {
  grid-area: name;
}

.form-container .field-container:nth-of-type(2) {
  grid-area: number;
}

.form-container .field-container:nth-of-type(3) {
  grid-area: expiration;
}

.form-container .field-container:nth-of-type(4) {
  grid-area: security;
}

input {
  margin-top: 3px;
  padding: 15px;
  font-size: 16px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
}

.field-container input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}


.form-container {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: auto auto;
  grid-template-rows: 90px 90px 90px;
  grid-template-areas: "name name""number number""expiration security";
  max-width: 400px;
  padding: 20px;
  color: #707070;
}

label {
  padding-bottom: 5px;
  font-size: 13px;
}

.ccicon {
  height: 38px;
  position: absolute;
  right: 6px;
  top: calc(50% - 17px);
  width: 60px;
}

.signature {
  font-family: 'Rock Salt', cursive;
}

/* CREDIT CARD IMAGE STYLING */
.preload * {
  transition: none !important;
}

.container {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  height: 54vw;
  padding: 20px;
  perspective: 1000px;
}

.ccsingle {
  position: absolute;
  right: 15px;
  top: 20px;
}

.ccsingle svg {
  width: 100px;
  max-height: 60px;
}

.creditcard svg#cardfront,
.creditcard svg#cardback {
  width: 100%;
  -webkit-box-shadow: 1px 5px 6px 0px black;
  box-shadow: 1px 5px 6px 0px black;
  border-radius: 22px;
}

#generatecard{
  cursor: pointer;
  float: right;
  font-size: 12px;
  color: #fff;
  padding: 2px 4px;
  background-color: #909090;
  border-radius: 4px;
}

/* CHANGEABLE CARD ELEMENTS */
.creditcard .lightcolor,
.creditcard .darkcolor {
  -webkit-transition: fill .5s;
  transition: fill .5s;
}

.creditcard .transparent {
  fill: transparent;
}

.creditcard .blue {
  fill: #3c5288;
}


.creditcard .bluedark {
  fill: #19264b;
}

.creditcard .lightblue {
  fill: #03A9F4;
}

.creditcard .lightbluedark {
  fill: #0288D1;
}

.creditcard .red {
  fill: #ef5350;
}

.creditcard .reddark {
  fill: #d32f2f;
}

.creditcard .purple {
  fill: #ab47bc;
}

.creditcard .purpledark {
  fill: #7b1fa2;
}

.creditcard .cyan {
  fill: #26c6da;
}

.creditcard .cyandark {
  fill: #0097a7;
}

.creditcard .green {
  fill: #a9c5b9;
}

.creditcard .greendark {
  fill: #7fa192;
}

.creditcard .lime {
  fill: #d4e157;
}

.creditcard .limedark {
  fill: #afb42b;
}

.creditcard .yellow {
  fill: #ffeb3b;
}

.creditcard .yellowdark {
  fill: #f9a825;
}

.creditcard .orange {
  fill: #ff9800;
}

.creditcard .orangedark {
  fill: #ef6c00;
}

.creditcard .grey {
  fill: #bdbdbd;
}

.creditcard .greydark {
  fill: #616161;
}

/* FRONT OF CARD */
#svgname {
  text-transform: uppercase;
}

#cardfront .st2 {
  fill: #FFFFFF;
}

#cardfront .st3 {
  font-family: 'Source Code Pro', monospace;
  font-weight: 600;
}

#cardfront .st4 {
  font-size: 54.7817px;
}

#cardfront .st5 {
  font-family: 'Source Code Pro', monospace;
  font-weight: 400;
}

#cardfront .st6 {
  font-size: 33.1112px;
}

#cardfront .st7 {
  opacity: 0.6;
  fill: #FFFFFF;
}

#cardfront .st8 {
  font-size: 24px;
}

#cardfront .st9 {
  font-size: 36.5498px;
}

#cardfront .st10 {
  font-family: 'Source Code Pro', monospace;
  font-weight: 300;
}

#cardfront .st11 {
  font-size: 16.1716px;
}

#cardfront .st12 {
  fill: #4C4C4C;
}

/* BACK OF CARD */
#cardback .st0 {
  fill: none;
  stroke: #0F0F0F;
  stroke-miterlimit: 10;
}

#cardback .st2 {
  fill: #111111;
}

#cardback .st3 {
  fill: #F2F2F2;
}

#cardback .st4 {
  fill: #D8D2DB;
}

#cardback .st5 {
  fill: #C4C4C4;
}

#cardback .st6 {
  font-family: 'Source Code Pro', monospace;
  font-weight: 400;
}

#cardback .st7 {
  font-size: 27px;
}

#cardback .st8 {
  opacity: 0.6;
}

#cardback .st9 {
  fill: #FFFFFF;
}

#cardback .st10 {
  font-size: 24px;
}

#cardback .st11 {
  fill: #EAEAEA;
}

#cardback .st12 {
  font-family: 'Rock Salt', cursive;
}

#cardback .st13 {
  font-size: 37.769px;
}

/* FLIP ANIMATION */


.creditcard {
  width: 100%;
  max-width: 400px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: -webkit-transform 0.6s;
  -webkit-transition: -webkit-transform 0.6s;
  cursor: pointer;
}

.creditcard .front,
.creditcard .back {
  position: absolute;
  width: 100%;
  max-width: 400px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  color: #47525d;
}

.creditcard .back {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.creditcard.flipped {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.back.vs-black{
  background-image: url(img/VISA-SIGNATURE-back.svg);
}

.back.mc-black,
.back.mc-chip-black {
  background-image: url(img/Tarjetas-Mastercard-BLACK-back.svg);
}

.back.vs-platinum {
  background-image: url(img/Tarjetas-VISA-CRÉDITO-PLATINUM-back.svg);
}

.back.mc-platinum {
  background-image: url(img/Tarjetas-Mastercard-PLATINUM-back.svg);
}

.back.mc-platinum,
.back.mc-chip-platinum {
  background-image: url(img/Tarjetas-Mastercard-PLATINUM-back.svg);
}

.back.vs-gold {
  background-image: url(img/Tarjetas-VISA-GOLD-back.svg);
}

.back.mc-gold {
  background-image: url(img/Tarjetas-Mastercard-GOLD-back.svg);
}

.back.vs-inter,
.back.vs-corporativa,
.back.vs-regional,
.back.vs-business {
  background-image: url(img/Tarjetas-VISA-INTERNACIONAL-back.svg);
}

.back.mc-inter,
.back.mc-regional {
  background-image: url(img/Tarjetas-Mastercard-INTERNACIONAL-back.svg);
}

.back.vs-debito {
  background-image: url(img/Tarjetas-VISA-DÉBITO-back.svg);
}

.hidden {
  display: none;
}
</style>
