import cardMixin from '@/mixins/card'
import BlackoutLoader from '@/components/shared/BlackoutLoader'
import installment from '@/components/Installment'

export default {
  name: 'MobileForm',
  mixins: [cardMixin],
  components: {
    BlackoutLoader,
    installment
  },
  data () {
    return {
      step: 1,
      isActive: false
    }
  },
  methods: {
    prev () {
      if (this.step === 5) {
        this.cvc = null
        let placeholder = '•••'
        if (this.cardtype === 'american express') {
          placeholder = '••••'
        }
        this.$cardBus.$emit('set-cvc', placeholder)
      }
      if (this.step === 6) {
        this.installment = ''
      }
      this.step--
    },
    next () {
      console.log(this.installments)
      this.step++
    }
  }
}
