import { http } from '@/services/http'
import CardSprite from '@/components/shared/CardSprites'
export default {
  name: 'TokenizedCard',
  model: {
    prop: 'activeCard',
    event: 'onSelectCard'
  },
  props: {
    index: { type: Number },
    activeCard: { type: Number },
    allowDelete: { type: Boolean },
    cardType: { type: String },
    card: { type: Object },
    enabled: { type: Boolean },
    deleteUrl: { type: String },
    deletedCard: { type: String }
  },
  components: {
    CardSprite
  },
  methods: {
    selectCard () {
      if (this.enabled) {
        this.$emit('onSelectCard', this.index)
      }
    },
    deleteCard () {
      const link = this.deleteUrl + '&alias_process=' + this.card.token.toString()
      let success = false
      http.post(link).then(res => {
        console.log(res)
        if (res.status === 200) {
          if (res.data.status.toUpperCase() === 'OK') {
            this.deletedCard = this.card.token
            success = true
            this.$emit('delete', this.index, success)
          } else {
            this.$emit('delete', this.index, success)
          }
        } else {
          console.log(res.status)
          this.$emit('delete', this.index, success)
        }
      })
    }
  }
}
