<template lang="pug">
.row
  .col-lg-12
    h4 Ingresá nueva tarjeta
  .container-form
    .card-wrapper
      credit-card
    .form-wrapper.col-lg-4
      desktop-form(v-model='data')
    .mobile-form-wrapper
      mobile-form(v-model='data')
</template>

<script>
// import CreditCard from '@/components/PaymentCheckout/CreditCardNew'
import CreditCard from '@/components/PaymentCheckout/CreditCard'
import DesktopForm from '@/components/PaymentCheckout/DesktopForm'
import MobileForm from '@/components/PaymentCheckout/MobileForm'

export default {
  props: {
    data: { type: [String, Object] }
  },
  components: {
    DesktopForm,
    MobileForm,
    CreditCard
  },
  data () {
    return {
      dataObject: Object
    }
  },
  methods: {
    parseData () {
      if (typeof this.data === 'string') {
        this.dataObject = JSON.parse(this.data)
      }
    },
    loadSDK () {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = this.dataObject.urls.api
      document.body.appendChild(script)
    }
  },
  created () {
    this.parseData()
    this.loadSDK()
  }
}
</script>

<style lang="scss" scoped>
@import './PaymentCheckout.scss';
</style>
