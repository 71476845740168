<template lang="pug">
  .Loader-container
    .Loader-content
      spinner
</template>

<script>
import Spinner from '@/components/shared/Spinner.vue'

export default {
  name: 'loader',
  components: { Spinner }
}
</script>

<style lang="scss" scoped>
  .Loader {
    &-content {
      padding: 20px;
      min-width: 40vh;
    }
  }
</style>
