import { numberFormat } from '@/filters/number-format'

export default {
  name: 'Installment',
  model: {
    prop: 'activeInstallment',
    event: 'onSelectInstallment'
  },
  props: {
    index: { type: Number },
    activeInstallment: { type: Number },
    installment: { type: Object }
  },
  computed: {
    finalPrice () {
      const final = (this.installment.price) * this.installment.installment
      return final
    }
  },
  methods: {
    selectInstallment () {
      this.$emit('onSelectInstallment', this.index)
    }
  },
  filters: {
    numberFormat
  }
}
