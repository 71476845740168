import { render, staticRenderFns } from "./Installment.pug?vue&type=template&id=53385772&scoped=true&lang=pug&"
import script from "./Installment.js?vue&type=script&lang=js&"
export * from "./Installment.js?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=53385772&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53385772",
  null
  
)

export default component.exports