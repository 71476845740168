<template lang="pug">
  .Card(aria-hidden='false')
    .Card-container(itemscope='', itemtype='https://schema.org/Product')
      .CardHeader
        .CardHeader-image
          figure
            img.replace-2x.img-responsive(loading="lazy" :src='shop.background' :alt='shop.name', :title='shop.name', itemprop='image')

      .CardBody
        .CardBody-image
          figure
            img.replace-2x.img-responsive(loading="lazy" :src='shop.image', :alt='shop.name', :title='shop.name', itemprop='image')

      .CardFooter
        a.CardFooter-button(:href='shop.link', :title='shop.name', itemprop='url') Mirá más
</template>

<script>
export default {
  name: 'CardShop',
  props: {
    shop: { type: Object, required: true }
  }
}
</script>

<style lang="scss" scoped>
  $primary: #ed2224;
  $secondary: #444445;
  $color-grey: #6a6b6d;
  $color-white: #fff;
  $background-gradient: linear-gradient(45deg,rgba(237,34,36,1)  0,rgba(130,22,67,1) 100%);
  $background-gradient-invested: linear-gradient(45deg, rgba(130,22,67,1) 0, rgba(237,34,36,1)100%);
  $width-card: 80vw;
  $root-font-size: 1rem;

  .Card {
    font-size: 1rem;
    padding:1em;
    width: 100%;
    height: auto;

    &-container {
      max-width: $width-card;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-shadow: 0 0 6px 0  rgba(0, 0, 0, 0.2);
      border-radius: 1rem;
      background: #fff;

      &:hover {
        cursor: pointer;
      }
    }

    &Header {
      height:17.2rem;
      margin-bottom:-50px;

      &-image figure {
        width: 100%;
        height: auto;

        img {
          border-radius: 1rem 1rem 0 0;
          object-fit: cover;
          height: 17.2rem;
          width: 100%;
        }
      }
    }

    &Body {
      height: auto;
      margin-bottom: 1.2rem;

      &-image{
        width: 100%;
        display: flex;
        justify-content: center;

        figure {
          box-shadow: 0 0 6px 0  rgba(0, 0, 0, 0.2);
          background-color: white;
          padding: 1rem;
          height: 100px;
          width: 100px;
          border-radius:.5rem;

          img {
            object-fit: contain;
            width:100%;
          }
        }
      }
    }

    &-title {
      font-size: 1.4em;
      font-weight: normal;
      margin: 0;
      text-align: center;
      text-transform: capitalize;
    }

    &Footer {
      display: flex;
      justify-content: center;
      padding: 0 0 .8rem 0;

      &-button {
        background:$background-gradient-invested;
        color: white;
        border: none;
        font-size: 1.1rem;
        text-transform: uppercase;
        padding: .4rem 2.5rem;
        font-weight: 600;
        border-radius: 2rem;
        text-decoration: none;
      }
    }
  }

  @media all and (min-width: 360px) {
    .Card {
      &Body {
        &-image {
          figure {
            object-fit: contain;
            border-radius: .5rem;

            img {
              width: 100%;
            }
          }
        }
      }
    }
  }

  @media all and (min-width: 410px) {
    .Card {
      &Body {
        &-image {
          object-fit: contain;
          border-radius:.5rem;

          img {
            width: 100%;
          }
        }
      }
    }
  }
  /* @media all and (min-width: 768px) {

  } */

  @media all and (min-width: 991px) {
    .Card {
      &Body {
        &-image {

          figure {
            object-fit: contain;
            border-radius: .5rem;

            img {
              width: 100%;
            }
          }
        }
      }
    }
  }

  /* @media all and (min-width: 1900px) {
    .Card {
      &Body {

      }
    }
  } */

</style>
