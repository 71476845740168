export default {
  name: 'CustomSelect',
  props: {
    options: { type: Array, required: true },
    placeholder: { type: String, required: false },
    label: { type: String, default: 'label' }
  },
  data () {
    return {
      isActive: false,
      selectedOption: null
    }
  },
  methods: {
    toggleSelect () {
      this.isActive = !this.isActive
    },
    selectOption (option) {
      this.isActive = false
      console.log(this.isActive)
      console.log(option)
      this.selectedOption = this.getLabel(option)
    },
    getLabel (option) {
      if (typeof option === 'object') {
        if (!option.hasOwnProperty(this.label)) {
          return console.warn(
            `[vue-select warn]: Label key "option.${this.label}" does not` +
            ` exist in options object ${JSON.stringify(option)}.\n` +
            'https://vue-select.org/api/props.html#getoptionlabel'
          )
        }
        return option[this.label]
      }
      return option
    }
  }
}
