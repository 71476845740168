<template lang="pug">
  .row(v-show='isShow')
    .container
      .col-md-6
        label.control-label.requiredField.tittle(for='Enterprise')
          | Ingresá tu número de celular
          span.asteriskField &nbsp;*
        .form-inline
          vue-tel-input.col-md-12(class="phone-class", @onInput="onInput", :onlyCountries="countries", placeholder="Ingresá tu número de celular")

        .form-group
          label.control-label.requiredField(for='Enterprise')
            | Seleccioná tu empresa
            span.asteriskField &nbsp;*

          select.select.form-control.input-lg(name='Enterprise', class="select-class center-block", v-model="enterpriseSelected")
            option(v-for="data in enterprises" :value='data.id') {{ data.value }}

        .row.col-sm-12
          button.btn.btn-default.pull-right(@click.prevent="openDialog", type="button") Siguiente

      .col-md-6.legalInfo
        p Por favor te pedimos que verifiques que el número de telefono ingresado sea correcto ya que una vez acreditado el saldo, el mismo no puede ser devuelto.

      .overlay(v-show="modal")
        .overlay-content
          .modal-dialog(role='document')
            .modal-content
              .modal-header
                //- button.close(@click.prevent="closeDialog" type='button' data-dismiss='modal' aria-label='Close')
                  //- span(aria-hidden='false') &times;
                h4.modal-title Confirmá tu número
              .modal-body
                div El número ingresado es: <strong>{{ phone.number }}</strong>, de la empresa: <strong>{{ enterprises[enterpriseSelected].value }}</strong>
                im-loader(v-show="showLoader")
                .text-center(v-show="showLoader") Espere hasta que finalice...
              .modal-footer
                button.btn.btn-default(@click.prevent="closeDialog" type='button' data-dismiss='modal', :disabled='btnDisabled') No, quiero cambiarlo
                button.btn.okBttn(@click.prevent="sendPhone" type='button', :disabled='btnDisabled') Si, es correcto
</template>

<script>
import VueTelInput from 'vue-tel-input'
import ImLoader from '@/components/shared/Loader.vue'
import { http } from '@/services/http'

export default {
  name: 'ElectroRecharge',
  props: {
    destiny: { type: String, required: false, default: null }
  },
  components: {
    VueTelInput,
    ImLoader
  },
  data () {
    return {
      countries: ['ar'],
      enterpriseSelected: 0,
      enterprises: [
        { id: 0, value: '-- Seleccionar --' },
        { id: 1, value: 'Movistar' },
        { id: 2, value: 'Claro' },
        { id: 3, value: 'Personal' },
        // { id: 4, value: 'Nextel' },
        { id: 33, value: 'Tuenti' }
      ],
      phone: {
        number: '',
        isValid: false
      },
      isShow: true,
      modal: false,
      btnDisabled: false,
      showLoader: false
    }
  },
  methods: {
    onInput ({ number, isValid }) {
      this.phone.number = number
      this.phone.isValid = isValid
    },
    sendPhone (event, phone) {
      this.btnDisabled = true
      this.showLoader = true

      let number = this.completeNumber(this.phone.number)

      let data = {
        number: number,
        enterprise: this.enterprises[this.enterpriseSelected]
      }

      http.post(this.destiny, data)
        .then(res => {
          if (res.data.status === 'Success') {
            this.modal = false
            this.isShow = false
            this.showMethodPay()
          }
        })
    },
    openDialog () {
      if (this.phone.isValid && this.enterpriseSelected > 0) {
        this.modal = true
      } else {
        alert('Ingrese un numero valido')
      }
    },
    closeDialog () {
      // alert('debe ingresar un numero con el siguiente formato:\n 11-XXXX-XXXX')
      this.modal = false
    },
    showMethodPay (event) {
      let method = document.getElementsByClassName('cuadro')

      if (method.length) {
        method[0].style.display = 'block'
      } else {
        let bttnPay = document.getElementById('solopuntos')
        bttnPay.style.display = 'block'
      }
    },
    completeNumber (input) {
      if (input.slice(3, 6) === ' 9 ') { return input }

      return `${input.slice(0, 3)} 9 ${input.slice(4 + Math.abs(0))}`
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '~vue-tel-input/dist/vue-tel-input.css';

  .tittle{
    margin: 5px auto;
  }

  .legalInfo {
    margin-top: 10%;
  }

  .phone-class {
    margin: 5px auto;
    float: left;
  }

  .phone-btn {
    float: right;
    margin-top: 15px;
  }

  .okBttn {
    background-color:#c4161c;
    color: white;
  }

  .overlay {
    height: 100%;
    width: 100%;
    position: fixed; /* Stay in place */
    z-index: 1000; /* Sit on top */
    left: 0;
    top: 0;
    background-color: rgb(0,0,0); /* Black fallback color */
    background-color: rgba(0,0,0, 0.9); /* Black w/opacity */
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
  }
  .overlay-content {
    position: relative;
    top: 25%; /* 25% from the top */
    width: 100%; /* 100% width */
    text-align: center;

    .modal-content {
      width: 100% !important;
      margin: 0 0 0 0 !important;
    }
  }

  @media all and (min-width: 900px) {
    .legalInfo {
      margin-top: 4%;
      width: 30%;
    }
  }
</style>
