<template lang="pug">
    .container-fluid
      .Carousel-clearfix.row(:class="{ 'Carousel--shops': isShops, 'Carousel--medium': isMedium }", v-if="src")
        im-loader(v-show="isLoading")

        h4.Carousel-title.col-xs-12.col-sm-12(v-show="!isLoading", v-if="carousel.name") {{ carousel.name }} &nbsp;
          a.Carousel-link(:href="carousel.link", :alt="carousel.name", v-if="carousel.link") <span class="score">- &nbsp</span> <span>ver más</span>

        tiny-slider.Carousel-container(
          v-show="!isLoading",
          :autoInit="slider.autoInit",
          :responsive="slider.responsive",
          :mouseDrag="slider.mouseDrag",
          :nav="slider.nav",
          :navPosition="slider.navPosition",
          :swipeAngle="slider.swipeAngle",
          :speed="slider.speed",
          :controlsText="slider.controlsText"
          :slideBy="slider.slideBy"
          :onInit="slider.onInit"
          :center="slider.center"
          :gutter="slider.gutter"
          )
          .Carousel-slide(v-if="carousel.content == 'categories'", v-for="category in content", :key="category.id")
            im-card-category.Carousel-item(:category="category")
          .Carousel-slide(v-if="carousel.content == 'products'", v-for="product in content", :key="product.id_product")
            im-card-product.Carousel-item(:product="product")
          .Carousel-slide(v-if="carousel.content == 'shops'", v-for="shop in content", :key="shop.id_official_store")
            im-card-shop.Carousel-item(:shop="shop")
</template>

<script>
import TinySlider from '@/lib/tiny-slider'
import ImCardCategory from '@/components/CardCategory.vue'
import ImCardProduct from '@/components/CardProduct.vue'
import ImCardShop from '@/components/CardShop.vue'
import ImLoader from '@/components/shared/Loader.vue'
import { http } from '@/services/http'

export default {
  name: 'ImCarousel',
  props: {
    src: { type: String, required: true, default: null },
    autoplay: { type: String, required: false, default: '0' },
    pagination: { type: [String, Number], required: false, default: '1' },
    desktopScreen: { type: String, required: false, default: 'full' }
  },
  components: {
    TinySlider,
    ImCardCategory,
    ImCardProduct,
    ImCardShop,
    ImLoader
  },
  data () {
    return {
      slider: {
        autoInit: false,
        responsive: {
          320: {
            autoWidth: true,
            items: 1.2
          },
          360: {

          },
          480: {
            items: 2
          },
          768: {
            items: 4
          },
          1025: {
            items: 4
          },
          1300: {
            items: 5
          },
          1500: {
            items: 6
          }
        },
        mouseDrag: true,
        onInit: true,
        nav: true,
        navPosition: 'bottom',
        swipeAngle: 15,
        speed: 1000,
        slideBy: 4,
        controlsText: ['<i class="glyphicon glyphicon-menu-left"></i>', '<i class="glyphicon glyphicon-menu-right"></i>']
      },
      carousel: {},
      content: [],
      isLoading: true,
      isShops: false,
      gutter: 0
    }
  },
  computed: {
    isAutoPlay () {
      if (parseInt(this.autoplay) === 1) { return true }

      return false
    },

    isMedium () {
      if (this.desktopScreen === 'medium') { return true }

      return false
    },

    isPagination () {
      if (parseInt(this.pagination) === 1) { return true }

      return false
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    setConfigSlider () {
      switch (this.desktopScreen) {
        case 'full-6':
          this.slider.responsive[320].nav = false
          this.slider.responsive[320].controls = false

          this.slider.responsive[480].nav = false
          this.slider.responsive[480].items = 1.2

          this.slider.responsive[768].items = 4

          this.slider.responsive[1025].nav = false
          this.slider.responsive[1025].controls = true

          this.slider.responsive[1300].nav = false
          this.slider.responsive[1300].controls = true
          this.slider.responsive[1300].slideBy = 5

          this.slider.responsive[1500].nav = false
          this.slider.responsive[1500].controls = true
          this.slider.responsive[1500].slideBy = 6

          break
        case 'full':
          this.slider.responsive[320].nav = false
          this.slider.responsive[320].controls = false

          this.slider.responsive[480].controls = false
          this.slider.responsive[480].nav = false
          this.slider.responsive[480].items = 1.2

          this.slider.responsive[768].nav = false
          this.slider.responsive[768].items = 3

          this.slider.responsive[1025].nav = false
          this.slider.responsive[1025].controls = true
          this.slider.responsive[1025].slideBy = 5

          this.slider.responsive[1300].nav = false
          this.slider.responsive[1300].controls = true
          this.slider.responsive[1300].slideBy = 5

          this.slider.responsive[1500].nav = false
          this.slider.responsive[1500].controls = true
          this.slider.responsive[1500].slideBy = 6

          break
        case 'medium':
          this.slider.responsive[320].nav = false
          this.slider.responsive[320].controls = false
          this.slider.responsive[320].slideBy = 1

          this.slider.responsive[480].nav = false
          this.slider.responsive[480].items = 1.2
          this.slider.responsive[480].slideBy = 1

          this.slider.responsive[768].items = 2
          this.slider.responsive[768].slideBy = 2

          this.slider.responsive[1025].items = 2
          this.slider.responsive[1025].slideBy = 2
          this.slider.responsive[1025].nav = false
          this.slider.responsive[1025].controls = false

          this.slider.responsive[1300].items = 2
          this.slider.responsive[1300].slideBy = 2
          this.slider.responsive[1300].nav = false
          this.slider.responsive[1300].controls = true

          this.slider.responsive[1500].items = 3
          this.slider.responsive[1500].slideBy = 3
          this.slider.responsive[1500].nav = false
          this.slider.responsive[1500].controls = true
          break
        case 'justify-full-4':
          this.slider.responsive[320].nav = false
          this.slider.responsive[320].controls = false
          this.slider.responsive[320].slideBy = 1

          this.slider.responsive[480].nav = false
          this.slider.responsive[480].items = 1.2
          this.slider.responsive[480].slideBy = 1

          this.slider.responsive[768].items = 3
          this.slider.responsive[768].slideBy = 3

          this.slider.responsive[1025].items = 4
          this.slider.responsive[1025].slideBy = 4
          this.slider.responsive[1025].nav = false
          this.slider.responsive[1025].controls = false

          this.slider.responsive[1300].items = 4
          this.slider.responsive[1300].slideBy = 4
          this.slider.responsive[1300].nav = false
          this.slider.responsive[1300].controls = true

          this.slider.responsive[1500].items = 5
          this.slider.responsive[1500].slideBy = 5
          this.slider.responsive[1500].nav = false
          this.slider.responsive[1500].controls = true
          break
        default:
      }
    },
    getData () {
      this.isLoading = true

      http.get(this.src)
        .then(res => {
          let data = res.data

          this.content = data.content
          this.carousel = data.carousel

          if (data.carousel.content === 'shops') { this.isShops = true }

          this.setConfigSlider()

          setTimeout(() => {
            this.slider.autoInit = true
            this.isLoading = false
          }, 1)
        })
    }
  }
}
</script>

<style lang="scss">
  @import '~tiny-slider/src/tiny-slider';
  $primary: rgb(237, 34, 36);

  .Carousel-title {
    padding-top: 0;
    font-weight: 300;
    color: $primary;
    margin: 0px;
    font-size: 2rem;
    z-index: 1;

    a {
      text-decoration-color: rgb(161, 157, 157);
      font-size: 1.9rem;

      span {
        color:rgb(161, 157, 157);
      }
      span.score {
        color:$primary;
      }
    }
  }

  .tns-visually-hidden {
      position: relative;
      left: -10000em;
  }

  .tns-outer {
    position: relative;

    &:hover {
      .tns-controls {
        button {
          visibility: visible;
          opacity: 1;
          transition: opacity 0s linear;
          width: 4rem;
          height: 4rem;
          font-size: 2.5rem;
          border-radius: 50%;
          background-color: rgb(255, 255, 255);
          color: rgb(237, 34, 36);
          box-shadow: 0 2px 4px 0 rgba(0,0,0,.19);
          text-align: center;
          align-items: center;
          position: absolute;
          font-weight: 0;
          z-index: 1;
          top: 45%;
          box-sizing: border-box;
          text-decoration: none;
          appearance: none;
          border: none;
          cursor: pointer;
          outline: none;
          font-weight: normal;

          &[data-controls="prev"] {
            left: -.5rem;
            padding-right: .7rem;
          }
          &[data-controls="next"] {
            right: -1rem;
            padding-left: .7rem;
          }

          &:hover {
            box-shadow: 0 7px 16px 0 rgba(0,0,0,.2), 0 1px 3px 0 rgba(0,0,0,.1);
            transition: box-shadow .2s,-webkit-box-shadow .2s;
          }
        }
      }
    }

    .tns-controls {
      button {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 0s, opacity 0s linear;
        width: 4rem;
        height: 4rem;
        font-size: 2.5rem;
        border-radius: 50%;
        background-color: rgb(255, 255, 255);
        color: rgb(237, 34, 36);
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.19);
        text-align: center;
        align-items: center;
        position: absolute;
        font-weight: 0;
        z-index: 1;
        top: 45%;
        box-sizing: border-box;
        text-decoration: none;
        appearance: none;
        border: none;
        cursor: pointer;
        outline: none;
        font-weight: normal;

        &[data-controls="prev"] {
          left: -.5rem;
          padding-right:1rem;
        }

        &[data-controls="next"] {
          right: -1rem;
          padding-left: 1rem;
        }
      }
    }

    .tns-nav {
      text-align: center;

      button {
        border: 0px;
        width: 0px!important;
        height: 12px;
        border-radius: 50%;
        margin-right: 1rem;

        &:focus {
          outline: none;
        }
      }

      &-active {
        background-color: $primary;
        outline: none;
      }
    }
  }

  @media all and (min-width: 1025px) {
    .Carousel-title {
      color: $primary;
      margin: 0px;
      font-size: 2.3rem;
      z-index: 1;

      a {
        text-decoration-color: rgb(161, 157, 157);
        font-size: 2.2rem;
      }
    }
  }

</style>
<style lang="scss" scoped>
  .container-fluid {
    padding: 0rem 1rem 1rem 1rem;
  }

//   @media all and (min-width: 360px) {
//   /* @media all and (min-width: 410px) {
//   } */
//   /* @media all and (min-width: 768px) {
//   } */
/* @media all and (min-width: 768px) {
//   } */

</style>
